/*
 * Visual Studio 2015 dark style
 * Author: Nicolas LLOBERA <nllobera@gmail.com>
 */

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #1e1e1e;
  color: #dcdcdc;
}

.hljs-keyword,
.hljs-literal,
.hljs-symbol,
.hljs-name {
  color: #569cd6;
}

.hljs-link {
  color: #569cd6;
  text-decoration: underline;
}

.hljs-built_in,
.hljs-type {
  color: #4ec9b0;
}

.hljs-number,
.hljs-class {
  color: #b8d7a3;
}

.hljs-string,
.hljs-meta-string {
  color: #d69d85;
}

.hljs-regexp,
.hljs-template-tag {
  color: #9a5334;
}

.hljs-subst,
.hljs-function,
.hljs-title,
.hljs-params,
.hljs-formula {
  color: #dcdcdc;
}

.hljs-comment,
.hljs-quote {
  color: #57a64a;
  font-style: italic;
}

.hljs-doctag {
  color: #608b4e;
}

.hljs-meta,
.hljs-meta-keyword,
.hljs-tag {
  color: #9b9b9b;
}

.hljs-variable,
.hljs-template-variable {
  color: #bd63c5;
}

.hljs-attr,
.hljs-attribute,
.hljs-builtin-name {
  color: #9cdcfe;
}

.hljs-section {
  color: gold;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

/*.hljs-code {
  font-family:'Monospace';
}*/

.hljs-bullet,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #d7ba7d;
}

.hljs-addition {
  background-color: #144212;
  display: inline-block;
  width: 100%;
}

.hljs-deletion {
  background-color: #600;
  display: inline-block;
  width: 100%;
}

/* This file is imported in _app but i think it can be deleted */
.component-layout-light .outerbase-component {
    --color-gray-100: #ffffff;
    --color-gray-200: #f9f9f9;
    --color-gray-300: #eeeeee;
    --color-gray-400: #d0d0d0;
    --color-gray-500: #959497;
    --color-gray-600: #5e5d61;
    --color-gray-700: #343438;
    --color-gray-800: #05040d;

    --color-button-cancel-bg-hover: var(--color-red-400);
  }
.component-layout-dark .outerbase-component {
    --color-gray-100: #05040d;
    --color-gray-200: #121119;
    --color-gray-300: #1e1f24;
    --color-gray-400: #343438;
    --color-gray-500: #5e5d61;
    --color-gray-600: #959497;
    --color-gray-700: #c0c0c0;
    --color-gray-800: #ffffff;

    --color-button-cancel-bg-hover: var(--color-red-600);
  }
.outerbase-component {
  --color-red-50: #fef2f2;
  --color-red-100: #fee2e2;
  --color-red-200: #fecaca;
  --color-red-300: #fca5a5;
  --color-red-400: #f87171;
  --color-red-500: #ef4444;
  --color-red-600: #dc2626;
  --color-red-700: #b91c1c;
  --color-red-800: #991b1b;
  --color-red-900: #7f1d1d;
  --color-red-950: #450a0a;
}
.text-md {
  font-size: 14px;
  line-height: 21px;
}
.text-gray-300 {
  color: var(--color-gray-300);
}
.text-gray-400 {
  color: var(--color-gray-400);
}
.text-gray-500 {
  color: var(--color-gray-500);
}
.text-gray-800 {
  color: var(--color-gray-800);
}
.text-red-500 {
  color: var(--color-red-500);
}
.hover-text-white {
  color: #ffffff;
}
.border {
  border-width: 1px;
}
.border-gray-300 {
  border-color: var(--color-gray-300);
}
.border-gray-400 {
  border-color: var(--color-gray-400);
}
.border-red-500 {
  border-color: var(--color-red-500);
}
.rounded {
  border-radius: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.bg-red-400 {
  background-color: var(--color-red-400);
}
.hover-bg-red-500:hover {
    background-color: var(--color-red-500);
  }
.active-bg-red-500:hover {
    background-color: var(--color-red-500);
  }
.outerbase-cancel-button:hover {
    background-color: var(--color-button-cancel-bg-hover);
  }
.outerbase-cancel-button:active {
    background-color: var(--color-red--500);
  }

.localize-project-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #384861;
  border-radius: 6px;
}

.localize-project-avatar-inner-circle {
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.\!visible {
  visibility: visible !important;
}
.visible {
  visibility: visible;
}
.collapse {
  visibility: collapse;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inset-0 {
  inset: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-2 {
  bottom: 0.5rem;
}
.left-16 {
  left: 4rem;
}
.left-\[64px\] {
  left: 64px;
}
.right-0 {
  right: 0px;
}
.right-3 {
  right: 0.75rem;
}
.top-7 {
  top: 1.75rem;
}
.z-10 {
  z-index: 10;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.table-cell {
  display: table-cell;
}
.table-row {
  display: table-row;
}
.\!grid {
  display: grid !important;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-\[39px\] {
  height: 39px;
}
.h-\[45px\] {
  height: 45px;
}
.h-full {
  height: 100%;
}
.max-h-60 {
  max-height: 15rem;
}
.min-h-full {
  min-height: 100%;
}
.w-10 {
  width: 2.5rem;
}
.w-16 {
  width: 4rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-56 {
  width: 14rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-full {
  width: 100%;
}
.min-w-\[512px\] {
  min-width: 512px;
}
.max-w-xl {
  max-width: 36rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-none {
  flex: none;
}
.flex-shrink {
  flex-shrink: 1;
}
.border-collapse {
  border-collapse: collapse;
}
.origin-bottom-right {
  transform-origin: bottom right;
}
.origin-top-right {
  transform-origin: top right;
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.cursor-pointer {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.resize-none {
  resize: none;
}
.resize {
  resize: both;
}
.flex-col {
  flex-direction: column;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l {
  border-left-width: 1px;
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.border-light-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity));
}
.border-light-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(208 208 208 / var(--tw-border-opacity));
}
.border-light-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(149 148 151 / var(--tw-border-opacity));
}
.border-light-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(5 4 13 / var(--tw-border-opacity));
}
.border-light-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgb(131 79 248 / var(--tw-border-opacity));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.bg-dark-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-light-feedback-error {
  --tw-bg-opacity: 1;
  background-color: rgb(240 56 78 / var(--tw-bg-opacity));
}
.bg-light-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-light-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}
.bg-light-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
.bg-light-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(208 208 208 / var(--tw-bg-opacity));
}
.bg-light-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 52 56 / var(--tw-bg-opacity));
}
.bg-light-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 4 13 / var(--tw-bg-opacity));
}
.bg-light-primary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 79 248 / var(--tw-bg-opacity));
}
.bg-light-primary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 211 253 / var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-cover {
  background-size: cover;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.align-middle {
  vertical-align: middle;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-base {
  font-size: 14px;
  line-height: 21px;
}
.text-lg {
  font-size: 16px;
  line-height: 24px;
}
.text-sm {
  font-size: 12px;
  line-height: 18px;
}
.text-xl {
  font-size: 20px;
  line-height: 28px;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.leading-6 {
  line-height: 1.5rem;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.text-light-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-light-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(238 238 238 / var(--tw-text-opacity));
}
.text-light-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(208 208 208 / var(--tw-text-opacity));
}
.text-light-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(149 148 151 / var(--tw-text-opacity));
}
.text-light-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(94 93 97 / var(--tw-text-opacity));
}
.text-light-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(52 52 56 / var(--tw-text-opacity));
}
.text-light-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(5 4 13 / var(--tw-text-opacity));
}
.text-light-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(162 123 250 / var(--tw-text-opacity));
}
.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.underline {
  text-decoration-line: underline;
}
.placeholder-light-gray-500::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(149 148 151 / var(--tw-placeholder-opacity));
}
.placeholder-light-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(149 148 151 / var(--tw-placeholder-opacity));
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-30 {
  opacity: 0.3;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-100 {
  transition-duration: 100ms;
}
.duration-200 {
  transition-duration: 200ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
:root {
  --color-violet-50: #faf5ff;
  --color-violet-100: #e9d8fd;
  --color-violet-200: #d6bcfa;
  --color-violet-300: #b794f4;
  --color-violet-400: #9f7aea;
  --color-violet-500: #805ad5;
  --color-violet-600: #6b46c1;
  --color-violet-700: #553c9a;
  --color-violet-800: #44337a;
  --color-violet-900: #322659;
  --color-violet-base: #805ad5;
  --color-sky-50: #f0f9ff;
  --color-sky-100: #e0f2fe;
  --color-sky-200: #bae6fd;
  --color-sky-300: #7dd3fc;
  --color-sky-400: #38bdf8;
  --color-sky-500: #0ea5e9;
  --color-sky-600: #0284c7;
  --color-sky-700: #0369a1;
  --color-sky-800: #075985;
  --color-sky-900: #0c4a6e;
  --color-sky-950: #001b2e;
  --color-space-50: #d1d1d3;
  --color-space-100: #c1c1c4;
  --color-space-200: #b1b2b6;
  --color-space-300: #a1a2a7;
  --color-space-400: #929398;
  --color-space-500: #828389;
  --color-space-600: #73747a;
  --color-space-700: #64656a;
  --color-space-800: #55565a;
  --color-space-900: #46474a;
  --color-space-base: #828389;

  --color-gray-50: #f9fafb;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;
  --color-gray-900: #111827;
  --color-gray-950: #030712;

  /* THESE ARE LOW KEY DEPRECATED */

  /* Primary Button */
  --button-primary-bg-color: var(--color-primary);
  --button-primary-bg-color-accent: var(--color-primary-accent);
  --button-primary-text-color: white;

  /* Secondary Button */
  --button-secondary-bg-color: var(--color-secondary);
  --button-secondary-bg-color-accent: var(--color-secondary-accent);
  --button-secondary-text-color: white;

  --background-color-primary: #ffffff;
  --background-color-secondary: #f5f5f5;
  --background-color-tertiary: #eeebeb;
  --color-background-accent: rgba(240, 240, 240, 0.5);
  --color-background-accent-hover: rgba(240, 240, 240, 0.8);
  --text-color-primary: #ffffff;
  --text-color-secondary: #ffffff;
  --border-color: #303030;
  --color-shadow: #d3d3d3;

  --color-error: #e53e3e;

  /* Inputs */
  --color-input-border: rgba(0, 0, 0, 0.16);
  --color-input-border-hover: rgba(0, 0, 0, 0.32);
  --color-input-border-focus: rgba(0, 0, 0, 0.48);

  /* NEW ONES FOR REAL */
  --color-primary: var(--color-violet-500);
  --color-primary-accent: var(--color-violet-700);

  --color-background-gradient-left: var(--color-primary);
  --color-background-gradient-right: var(--color-violet-200);
  --color-background-gradient-left-secondary: var(--color-sky-600);
  --color-background-gradient-right-secondary: var(--color-sky-300);

  --color-secondary: var(--color-space-500);
  --color-secondary-accent: var(--color-space-700);

  --color-text-primary: var(--color-gray-950);
  --color-text-primary-accent: var(--color-gray-600);
  --color-text-primary-active: var(--color-primary);

  --color-text-secondary: var(--color-gray-500);
  --color-text-secondary-accent: var(--color-gray-700);

  --color-background-secondary: var(--color-gray-50);
  --color-background-tertiary: var(--color-gray-100);

  --color-background-active: var(--color-violet-100);

  --color-border: var(--color-gray-200);
  --color-border-accent: var(--color-gray-400);

  --gutter-x: 32px;
  --padding-y: 18px;
}
html {
  scroll-behavior: smooth;
}
.ob-clickable {
  cursor: pointer;
}
.dark {
  color: white;
}
.selection\:bg-light-primary-500 *::-moz-selection {
  --tw-bg-opacity: 1;
  background-color: rgb(162 123 250 / var(--tw-bg-opacity));
}
.selection\:bg-light-primary-500 *::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(162 123 250 / var(--tw-bg-opacity));
}
.selection\:bg-light-primary-500::-moz-selection {
  --tw-bg-opacity: 1;
  background-color: rgb(162 123 250 / var(--tw-bg-opacity));
}
.selection\:bg-light-primary-500::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(162 123 250 / var(--tw-bg-opacity));
}
.hover\:bg-dark-primary-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 60 189 / var(--tw-bg-opacity));
}
.hover\:bg-light-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}
.hover\:bg-light-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
.hover\:bg-light-gray-300\/70:hover {
  background-color: rgb(238 238 238 / 0.7);
}
.hover\:bg-light-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(208 208 208 / var(--tw-bg-opacity));
}
.hover\:bg-light-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(52 52 56 / var(--tw-bg-opacity));
}
.hover\:bg-light-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(5 4 13 / var(--tw-bg-opacity));
}
.hover\:bg-light-primary-700\/90:hover {
  background-color: rgb(224 211 253 / 0.9);
}
.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.hover\:bg-white\/10:hover {
  background-color: rgb(255 255 255 / 0.1);
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.focus\:border-light-primary-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(131 79 248 / var(--tw-border-opacity));
}
.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-visible\:border-purple-500:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}
.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-light-primary-400:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(131 79 248 / var(--tw-ring-opacity));
}
.focus-visible\:ring-white:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}
.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: 0.75;
}
.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}
.focus-visible\:ring-offset-purple-300:focus-visible {
  --tw-ring-offset-color: #d8b4fe;
}
.active\:bg-light-primary-700:active {
  --tw-bg-opacity: 1;
  background-color: rgb(224 211 253 / var(--tw-bg-opacity));
}
.active\:bg-red-500:active {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.active\:text-light-primary-400:active {
  --tw-text-opacity: 1;
  color: rgb(131 79 248 / var(--tw-text-opacity));
}
.disabled\:bg-light-gray-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
.disabled\:bg-transparent:disabled {
  background-color: transparent;
}
.group:hover .group-hover\:bg-light-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
:is(.dark .dark\:border-dark-gray-300) {
  --tw-border-opacity: 1;
  border-color: rgb(30 31 36 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-dark-gray-400) {
  --tw-border-opacity: 1;
  border-color: rgb(52 52 56 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-dark-gray-500) {
  --tw-border-opacity: 1;
  border-color: rgb(94 93 97 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-dark-gray-800) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-dark-primary-400) {
  --tw-border-opacity: 1;
  border-color: rgb(131 79 248 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-red-500) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
:is(.dark .dark\:bg-black) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-feedback-error) {
  --tw-bg-opacity: 1;
  background-color: rgb(240 56 78 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-gray-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(5 4 13 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-gray-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(18 17 25 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-gray-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 31 36 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-gray-400) {
  --tw-bg-opacity: 1;
  background-color: rgb(52 52 56 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-primary-400) {
  --tw-bg-opacity: 1;
  background-color: rgb(131 79 248 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-dark-primary-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(47 29 91 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-light-gray-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-light-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(5 4 13 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-opacity-25) {
  --tw-bg-opacity: 0.25;
}
:is(.dark .dark\:text-dark-gray-100) {
  --tw-text-opacity: 1;
  color: rgb(5 4 13 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-dark-gray-300) {
  --tw-text-opacity: 1;
  color: rgb(30 31 36 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-dark-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(52 52 56 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-dark-gray-500) {
  --tw-text-opacity: 1;
  color: rgb(94 93 97 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-dark-gray-600) {
  --tw-text-opacity: 1;
  color: rgb(149 148 151 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-dark-gray-700) {
  --tw-text-opacity: 1;
  color: rgb(192 192 192 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-dark-gray-800) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-dark-primary-400) {
  --tw-text-opacity: 1;
  color: rgb(131 79 248 / var(--tw-text-opacity));
}
:is(.dark .dark\:placeholder-dark-gray-500)::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(94 93 97 / var(--tw-placeholder-opacity));
}
:is(.dark .dark\:placeholder-dark-gray-500)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(94 93 97 / var(--tw-placeholder-opacity));
}
:is(.dark .dark\:hover\:bg-black\/10:hover) {
  background-color: rgb(0 0 0 / 0.1);
}
:is(.dark .dark\:hover\:bg-dark-gray-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 31 36 / var(--tw-bg-opacity));
}
:is(.dark .dark\:hover\:bg-dark-gray-400:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(52 52 56 / var(--tw-bg-opacity));
}
:is(.dark .dark\:hover\:bg-white:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
:is(.dark .hover\:dark\:bg-dark-gray-200):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(18 17 25 / var(--tw-bg-opacity));
}
:is(.dark .hover\:dark\:bg-dark-gray-300\/70):hover {
  background-color: rgb(30 31 36 / 0.7);
}
:is(.dark .hover\:dark\:bg-light-gray-300):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
:is(.dark .hover\:dark\:bg-light-primary-500):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(162 123 250 / var(--tw-bg-opacity));
}
:is(.dark .hover\:dark\:bg-red-600):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
:is(.dark .dark\:focus\:border-light-primary-400:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(131 79 248 / var(--tw-border-opacity));
}
:is(.dark .active\:dark\:bg-dark-primary-700):active {
  --tw-bg-opacity: 1;
  background-color: rgb(47 29 91 / var(--tw-bg-opacity));
}
:is(.dark .dark\:active\:bg-dark-primary-700:active) {
  --tw-bg-opacity: 1;
  background-color: rgb(47 29 91 / var(--tw-bg-opacity));
}
:is(.dark .active\:dark\:text-dark-primary-400):active {
  --tw-text-opacity: 1;
  color: rgb(131 79 248 / var(--tw-text-opacity));
}
:is(.dark .dark\:active\:text-dark-primary-400:active) {
  --tw-text-opacity: 1;
  color: rgb(131 79 248 / var(--tw-text-opacity));
}
:is(.dark .dark\:disabled\:bg-dark-gray-300:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 31 36 / var(--tw-bg-opacity));
}
:is(.dark .group:hover .dark\:group-hover\:bg-dark-gray-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(18 17 25 / var(--tw-bg-opacity));
}

.mrfxyz-button {
  font-weight: 700;
  letter-spacing: 0px;
  border-radius: 8px;
  transition: all 200ms;
  background-color: rgb(167, 167, 167);
  color: #ffffff;
}

.mrfxyz-button:hover {
  border-color: #ffffff;
  color: #ffffff;
}

.mrfxyz-button:active {
  border-color: #ffffff;
  color: #ffffff;
}

.mrfxyz-button:focus {
}

.mrfxyz-button:disabled {
  background-color: rgb(204, 204, 204) !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.mrfxyz-button .right-icon-spacing {
  margin-right: 8px;
}

.mrfxyz-button .left-icon-spacing {
  margin-left: 8px;
}

.mrfxyz-button-variant-primary {
  border-radius: 8px;
  transition: all undefined ms;
  background-color: #8455e4;
  color: #ffffff;
}

.mrfxyz-button-variant-primary:hover {
  background-color: #8455e4;
  border-color: #ffffff;
  color: #ffffff;
}

.mrfxyz-button-variant-primary:active {
  background-color: #7742e1;
  border-color: #ffffff;
  color: #ffffff;
}

.mrfxyz-button-variant-primary:focus {
}

.mrfxyz-button-variant-primary:disabled {
  background-color: #ddd0f8 !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.mrfxyz-button-variant-success {
  font-weight: 700;
  letter-spacing: 0px;
  border-radius: 8px;
  transition: all 200ms;
  background-color: green;
  color: #ffffff;
}

.mrfxyz-button-variant-success:hover {
  background-color: green;
  border-color: #ffffff;
  color: #ffffff;
}

.mrfxyz-button-variant-success:active {
  background-color: green;
  border-color: #ffffff;
  color: #ffffff;
}

.mrfxyz-button-variant-success:focus {
}

.mrfxyz-button-variant-success:disabled {
  background-color: rgba(97, 173, 55, 0.21) !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.mrfxyz-button-size-sm {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.mrfxyz-button-size-md {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mrfxyz-button-size-lg {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.mrfxyz-modal-container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mrfxyz-modal-overlay {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.mrfxyz-modal {
  position: fixed;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  min-width: 400px;
}

.mrfxyz-modal-enter {
  opacity: 0;
  transform: scale(0.9);
}

.mrfxyz-modal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 200ms;
}

.mrfxyz-modal-exit {
  opacity: 1;
}

.mrfxyz-modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: all 200ms;
}

.mrfxyz-modal-header {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.mrfxyz-modal-header-close-icon {
  cursor: pointer;
}

.mrfxyz-modal-body {
  padding: 12px;
}

.mrfxyz-modal-footer {
  padding: 10px 12px;
}

.mrfxyz-modal-header ~ .mrfxyz-modal-body {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: gray;
}

.mrfxyz-modal-body ~ .mrfxyz-modal-footer {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: gray;
}

