/* This file is imported in _app but i think it can be deleted */
.component-layout-light {
  .outerbase-component {
    --color-gray-100: #ffffff;
    --color-gray-200: #f9f9f9;
    --color-gray-300: #eeeeee;
    --color-gray-400: #d0d0d0;
    --color-gray-500: #959497;
    --color-gray-600: #5e5d61;
    --color-gray-700: #343438;
    --color-gray-800: #05040d;

    --color-button-cancel-bg-hover: var(--color-red-400);
  }
}

.component-layout-dark {
  .outerbase-component {
    --color-gray-100: #05040d;
    --color-gray-200: #121119;
    --color-gray-300: #1e1f24;
    --color-gray-400: #343438;
    --color-gray-500: #5e5d61;
    --color-gray-600: #959497;
    --color-gray-700: #c0c0c0;
    --color-gray-800: #ffffff;

    --color-button-cancel-bg-hover: var(--color-red-600);
  }
}

.outerbase-component {
  --color-red-50: #fef2f2;
  --color-red-100: #fee2e2;
  --color-red-200: #fecaca;
  --color-red-300: #fca5a5;
  --color-red-400: #f87171;
  --color-red-500: #ef4444;
  --color-red-600: #dc2626;
  --color-red-700: #b91c1c;
  --color-red-800: #991b1b;
  --color-red-900: #7f1d1d;
  --color-red-950: #450a0a;
}

.text-md {
  font-size: 14px;
  line-height: 21px;
}

.text-gray-300 {
  color: var(--color-gray-300);
}

.text-gray-400 {
  color: var(--color-gray-400);
}

.text-gray-500 {
  color: var(--color-gray-500);
}

.text-gray-800 {
  color: var(--color-gray-800);
}

.text-red-500 {
  color: var(--color-red-500);
}

.hover-text-white {
  color: #ffffff;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: var(--color-gray-300);
}

.border-gray-400 {
  border-color: var(--color-gray-400);
}

.border-red-500 {
  border-color: var(--color-red-500);
}

.rounded {
  border-radius: 0.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.select-none {
  user-select: none;
}

.bg-red-400 {
  background-color: var(--color-red-400);
}

.hover-bg-red-500 {
  &:hover {
    background-color: var(--color-red-500);
  }
}

.active-bg-red-500 {
  &:hover {
    background-color: var(--color-red-500);
  }
}

.outerbase-cancel-button {
  &:hover {
    background-color: var(--color-button-cancel-bg-hover);
  }

  &:active {
    background-color: var(--color-red--500);
  }
}
