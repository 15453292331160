.localize-project-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #384861;
  border-radius: 6px;
}

.localize-project-avatar-inner-circle {
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
