@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-violet-50: #faf5ff;
  --color-violet-100: #e9d8fd;
  --color-violet-200: #d6bcfa;
  --color-violet-300: #b794f4;
  --color-violet-400: #9f7aea;
  --color-violet-500: #805ad5;
  --color-violet-600: #6b46c1;
  --color-violet-700: #553c9a;
  --color-violet-800: #44337a;
  --color-violet-900: #322659;
  --color-violet-base: #805ad5;
  --color-sky-50: #f0f9ff;
  --color-sky-100: #e0f2fe;
  --color-sky-200: #bae6fd;
  --color-sky-300: #7dd3fc;
  --color-sky-400: #38bdf8;
  --color-sky-500: #0ea5e9;
  --color-sky-600: #0284c7;
  --color-sky-700: #0369a1;
  --color-sky-800: #075985;
  --color-sky-900: #0c4a6e;
  --color-sky-950: #001b2e;
  --color-space-50: #d1d1d3;
  --color-space-100: #c1c1c4;
  --color-space-200: #b1b2b6;
  --color-space-300: #a1a2a7;
  --color-space-400: #929398;
  --color-space-500: #828389;
  --color-space-600: #73747a;
  --color-space-700: #64656a;
  --color-space-800: #55565a;
  --color-space-900: #46474a;
  --color-space-base: #828389;

  --color-gray-50: #f9fafb;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;
  --color-gray-900: #111827;
  --color-gray-950: #030712;

  /* THESE ARE LOW KEY DEPRECATED */

  --color-primary: var(--color-violet-500);
  --color-primary-accent: var(--color-violet-700);

  --color-secondary: var(--color-space-500);
  --color-secondary-accent: var(--color-space-700);

  /* Primary Button */
  --button-primary-bg-color: var(--color-primary);
  --button-primary-bg-color-accent: var(--color-primary-accent);
  --button-primary-text-color: white;

  /* Secondary Button */
  --button-secondary-bg-color: var(--color-secondary);
  --button-secondary-bg-color-accent: var(--color-secondary-accent);
  --button-secondary-text-color: white;

  --background-color-primary: #ffffff;
  --background-color-secondary: #f5f5f5;
  --background-color-tertiary: #eeebeb;
  --color-background-accent: rgba(240, 240, 240, 0.5);
  --color-background-accent-hover: rgba(240, 240, 240, 0.8);
  --text-color-primary: #ffffff;
  --text-color-secondary: #ffffff;
  --border-color: #303030;
  --color-shadow: #d3d3d3;

  --color-error: #e53e3e;

  /* Inputs */
  --color-input-border: rgba(0, 0, 0, 0.16);
  --color-input-border-hover: rgba(0, 0, 0, 0.32);
  --color-input-border-focus: rgba(0, 0, 0, 0.48);

  /* NEW ONES FOR REAL */
  --color-primary: var(--color-violet-500);
  --color-primary-accent: var(--color-violet-700);

  --color-background-gradient-left: var(--color-primary);
  --color-background-gradient-right: var(--color-violet-200);
  --color-background-gradient-left-secondary: var(--color-sky-600);
  --color-background-gradient-right-secondary: var(--color-sky-300);

  --color-secondary: var(--color-space-500);
  --color-secondary-accent: var(--color-space-700);

  --color-text-primary: var(--color-gray-950);
  --color-text-primary-accent: var(--color-gray-600);
  --color-text-primary-active: var(--color-primary);

  --color-text-secondary: var(--color-gray-500);
  --color-text-secondary-accent: var(--color-gray-700);

  --color-background-secondary: var(--color-gray-50);
  --color-background-tertiary: var(--color-gray-100);

  --color-background-active: var(--color-violet-100);

  --color-border: var(--color-gray-200);
  --color-border-accent: var(--color-gray-400);

  --gutter-x: 32px;
  --padding-y: 18px;
}

html {
  scroll-behavior: smooth;
}

.ob-clickable {
  cursor: pointer;
}

.dark {
  color: white;
}
